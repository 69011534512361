import router from "@/router/index.js";
import MealTicketService from "~/service/mealTicketService";
import axios from "~/plugins/axios";

function handlePayResponseKo(data, orderId) {
  var cart = data.data;

  if (!orderId) {
    orderId = cart && cart.order ? cart.order.orderId : null;
  }
  router.push("/payment-error/" + orderId);
}

function stripeRecurrentPayment(paymentUrl) {
  var data = paymentUrl.split("?");
  paymentUrl = "/ebsn/api/stripe/payment-feedback-recurrent";

  var params = data[1].split("&");
  var values = [];

  for (var i = 0; i < params.length; i++) {
    values[params[i].split("=")[0]] = params[i].split("=")[1];
  }

  axios
    .get(paymentUrl, {
      params: {
        redirect_status: values["redirect_status"],
        payment_id: values["payment_id"],
        payment_intent_client_secret: values["payment_intent_client_secret"]
      }
    })
    .then(data => {
      console.log(data);
      //window.location =data;
    });
}

function payment(paymentUrl, paymentParameters, method, orderId) {
  var ref;
  switch (method) {
    case "get":
      if (window.cordova && window.cordova.InAppBrowser) {
        ref = window.cordova.InAppBrowser.open(
          paymentUrl,
          "_blank",
          "location=no,toolbar=no,zoom=no,hardwareback=no,enableViewportScale=yes"
        );
        if (ref) {
          ref.addEventListener("loadstart", function(args) {
            var url = args.url;
            if (url.indexOf("/payment-completed") > 0) {
              ref.close();
              router.push("/cart/payment-completed/" + orderId);
            } else if (url.indexOf("/payment-error") > 0) {
              ref.close();
              router.push("/cart/payment-error/" + orderId);
            } else if (
              url.indexOf("/payment") > 0 &&
              url.indexOf("/UNI_CG_WEB/app/cc") < 0 &&
              url.indexOf("/monetaweb/do/") < 0
            ) {
              ref.close();
              router.push("/payment");
            }
          });
          ref.addEventListener("exit", function(args) {
            var url = args.url;
            if (url.indexOf("/payment-completed") < 0) {
              router.push("/cart/payment-completed/" + orderId);
            }
          });
        }
      } else {
        if (paymentUrl.indexOf("/stripe-pay-payment") > -1) {
          router.push({
            name: "StripePayPayment",
            params: {
              order: paymentParameters.order,
              payment_client_secret: paymentParameters.payment_client_secret,
              payment_data: paymentParameters.payment_data,
              id: paymentParameters.id,
              orderGrossTotal: paymentParameters.orderGrossTotal,
              timeSlotDate: paymentParameters.timeSlotDate,
              timeSlotTime: paymentParameters.timeSlotTime,
              someValueToPass: String
            }
          });
        } else if (
          paymentUrl.indexOf("/ebsn/api/stripe/payment-feedback-recurrent") > -1
        ) {
          router.push({
            name: "StripePayPayment",
            params: {
              order: paymentParameters.order,
              payment_client_secret: paymentParameters.payment_client_secret,
              payment_data: paymentParameters.payment_data,
              id: paymentParameters.id,
              orderGrossTotal: paymentParameters.orderGrossTotal,
              timeSlotDate: paymentParameters.timeSlotDate,
              timeSlotTime: paymentParameters.timeSlotTime,
              redirect_url_recurrent: paymentUrl,
              payment_auth_id: paymentParameters.payment_auth_id,
              someValueToPass: String
            }
          });

          //  stripeRecurrentPayment(paymentUrl);
        } else {
          window.location = paymentUrl;
        }
      }
      break;
    case "post":
      // POST FA UNA SUBMIT CREANDO UNA FORM DINAMICA
      // DIPENDE DAL GATEWAY DI PAGAMENTO (Es. sotto Nexi.)
      var pageContent = [];
      var form = document.createElement("form");
      form.setAttribute("method", method);
      form.setAttribute("action", paymentUrl);

      // Page Content for InAppBrowser
      pageContent.push(
        '<html><head></head><body><form id="paymentForm" action="' +
          paymentUrl +
          '" method="' +
          method +
          '">'
      );

      for (var key in paymentParameters) {
        // eslint-disable-next-line no-prototype-builtins
        if (paymentParameters.hasOwnProperty(key)) {
          if (key != "redirectUrl") {
            var hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", key);
            hiddenField.setAttribute("value", paymentParameters[key]);

            pageContent.push(
              '<input type="hidden" name="' +
                key +
                '" value="' +
                paymentParameters[key] +
                '">'
            );

            form.appendChild(hiddenField);
          }
        }
      }

      pageContent.push(
        '</form> <script type="text/javascript">document.getElementById("paymentForm").submit();</script></body></html>'
      );

      if (window.cordova && window.cordova.InAppBrowser) {
        var pageContentUrl = pageContent.join("");
        ref = window.cordova.InAppBrowser.open(
          "data:text/html;base64," + btoa(pageContentUrl),
          "_blank",
          "location=no,toolbar=yes,zoom=no,hardwareback=no,enableViewportScale=yes"
        );
        if (ref) {
          ref.addEventListener("loadstart", function(args) {
            var url = args.url;
            if (url.indexOf("/cart/payment-completed") > 0) {
              ref.close();
              router.push("/cart/payment-completed/" + orderId);
            }
            if (url.indexOf("/cart/payment-error") > 0) {
              ref.close();
              router.push("/cart/payment-error/" + orderId);
            }
          });
        }
      } else {
        document.body.appendChild(form);
        setTimeout(() => {
          form.submit();
        }, 100);
      }
      break;
  }
}
function handleRedirectEdenred(redirectUrl) {
  var ref;
  //TODO APP
  if (window.cordova && window.cordova.InAppBrowser) {
    ref = window.cordova.InAppBrowser.open(
      redirectUrl,
      "_blank",
      "location=no,toolbar=no,zoom=no,hardwareback=no,enableViewportScale=yes"
    );
    if (ref) {
      ref.addEventListener("loadstart", function(args) {
        var url = args.url;

        if (url.indexOf("http://test.digi.local") == 0) {
          //NUOVA CHIAMATA CON IL TOKEN EDENRED -->
          console.log(url);
          var code = url.split("code=")[1];
          var token = code.split("&scope")[0];
          if (url.indexOf("callback-tc") < 0) {
            MealTicketService.validateToken(token).then(data => {
              console.log(data);
              ref.close();
            });
          } else {
            MealTicketService.validateTokenTC(token).then(data => {
              console.log(data);
              ref.close();
            });
          }
        }
      });
      ref.addEventListener("exit", function() {
        setTimeout(() => {
          router.push("/payment", window.location.reload()).catch(err => err);
        }, 100);
      });
    }
  } else {
    window.location = redirectUrl;
  }
}

function handleLogoutEdenred(redirectUrl) {
  var ref;
  //TODO APP
  if (window.cordova && window.cordova.InAppBrowser) {
    ref = window.cordova.InAppBrowser.open(
      redirectUrl,
      "_blank",
      "location=no,toolbar=no,zoom=no,hardwareback=no,enableViewportScale=yes"
    );
    if (ref) {
      ref.addEventListener("loadstart", function(args) {
        var url = args.url;

        if (url.indexOf("http://test.digi.local") == 0) {
          console.log(url);
          if (url.indexOf("callback-tc") < 0) {
            MealTicketService.validateLogoutToken().then(data => {
              console.log(data);
              ref.close();
            });
          } else {
            MealTicketService.validateLogoutTokenTC().then(data => {
              console.log(data);
              ref.close();
            });
          }
        }
      });
      ref.addEventListener("exit", function() {
        router.push("/payment", window.location.reload()).catch(err => err);
      });
    }
  } else {
    window.location = redirectUrl;
  }
}

export {
  handlePayResponseKo,
  payment,
  handleRedirectEdenred,
  handleLogoutEdenred,
  stripeRecurrentPayment
};
